import "./LogInPage.css"
import { InputText } from "primereact/inputtext"
import Navbar from "../components/Navbar"
import { useRef, useState } from "react"
import { Button } from "primereact/button"
import { Toast } from 'primereact/toast';
import React from "react"

export function LogInPage() {

    const [email, setEmail] = useState<string>("")
    const [password, setPassword] = useState<string>("")
    const [isRecoveringPassword, setIsRecoveringPassword] = useState<boolean>(false)

    const toast = useRef<Toast>(null);
    const showError = () => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Incorrect email or password.' });
    };
    const showPasswordRecoveryMessage = () => {
        toast.current?.show({ severity: 'info', summary: 'Recovery', detail: 'If your email address is associated with a known account, you should receive an email with recovery instructions shortly.' });
    }

    return (
        <div className="login-page">
            <Navbar hideButtons />
            <Toast ref={toast} />
            <div className="login-page__form-container">
                <h1 className="login-page__form-container__title-emoji">👋</h1>
                <h1 className="login-page__form-container__title">
                    Welcome Back!
                </h1>
                {
                    !isRecoveringPassword ?
                    <form 
                        className="login-page__form"
                        onSubmit={(e) => {
                            e.preventDefault()
                            showError()
                        }}
                    >
                        <p className="login-page__form-container__description">
                            Please enter a password to continue to ArchiLabs Studio.
                        </p>
                        <div className="login-page__form__input-row">
                            <span className="p-float-label login-page__form__input">
                                <InputText 
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email} 
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} 
                                />
                                <label htmlFor="email">Email</label>
                            </span>
                        </div>
                        <div className="login-page__form__input-row">
                            <span className="p-float-label login-page__form__input">
                                <InputText
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                                />
                                <label htmlFor="password">Password</label>
                            </span>
                        </div>
                        <p className="login-page__form__forgot-password">
                            <button className="login-page__form__text-link" onClick={() => setIsRecoveringPassword(true)}>Forgot Password?</button>
                        </p>
                        <Button 
                            className="login-page__form__submit" 
                            label="Continue"
                        />
                        <small className="login-page__form__small-text">
                            Don't have an account? <a className="login-page__form__text-link" href="mailto: info@antoninelabs.com"><b>Email us!</b></a>
                        </small>
                    </form>
                    :
                    <form 
                        className="login-page__form"
                        onSubmit={(e) => {
                            e.preventDefault()
                            showPasswordRecoveryMessage()
                        }}
                    >
                        <p className="login-page__form-container__description">
                            Please enter the email address of your account to reset your password.
                        </p>
                        <div className="login-page__form__input-row">
                            <span className="p-float-label login-page__form__input">
                                <InputText 
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={email} 
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} 
                                />
                                <label htmlFor="email">Email</label>
                            </span>
                        </div>
                        <Button 
                            className="login-page__form__submit" 
                            label="Continue"
                        />
                        <small className="login-page__form__small-text">
                            Have your password? <button className="login-page__form__text-link" onClick={() => setIsRecoveringPassword(false)}><b>Log in now.</b></button>
                        </small>
                    </form>
                }
                
            </div>
        </div>
    )
}