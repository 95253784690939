import { useRef, useState } from "react";
import "./ContactSection.css"
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from "primereact/toast";
import React from "react";

export default function ContactSection() {

    const [firstName, setFirstName] = useState<string>("")
    const [lastName, setLastName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [companyName, setCompanyName] = useState<string>("")
    const [message, setMessage] = useState<string>("")
    const [subscribeToUpdates, setSubscribeToUpdates] = useState<boolean>(false)

    const [firstNameError, setFirstNameError] = useState<boolean>(false)
    const [lastNameError, setLastNameError] = useState<boolean>(false)
    const [emailError, setEmailError] = useState<boolean>(false)
    const [companyNameError, setCompanyNameError] = useState<boolean>(false)
    const [messageError, setMessageError] = useState<boolean>(false)

    const toast = useRef<Toast>(null);
    const showError = () => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Please fill in all required fields.' });
    };
    const showSuccess = () => {
        toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Your message has been sent.' });
    };
    const showServerError = () => {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'There was an error sending your message. Please try again later.' });
    };

    const validateForm = (formData: {
        firstname: string,
        lastname: string,
        email: string,
        company: string,
        message: string,
        subscribeToUpdates: boolean,
    }) => {
        if (formData.firstname === "") {
            setFirstNameError(true)
        } else {
            setFirstNameError(false)
        }
        if (formData.lastname === "") {
            setLastNameError(true)
        } else {
            setLastNameError(false)
        }
        if (
            formData.email === "" ||
            !formData.email.includes("@") ||
            !formData.email.includes(".")
        ) {
            setEmailError(true)
        } else {
            setEmailError(false)
        }
        if (formData.company === "") {
            setCompanyNameError(true)
        } else {
            setCompanyNameError(false)
        }
        if (formData.message === "") {
            setMessageError(true)
        } else {
            setMessageError(false)
        }
        if (
            formData.firstname === "" || 
            formData.lastname === "" || 
            formData.email === "" || 
            formData.company === "" || 
            formData.message === ""
        ) {
            return false
        } else {
            return true
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const formDataObject = {
            firstname: firstName,
            lastname: lastName,
            email,
            company: companyName,
            message,
            subscribeToUpdates,
        }

        if (validateForm(formDataObject)) {

            const contactForm = event.currentTarget;
            const formData = new FormData(contactForm);
          
            const params = new URLSearchParams();
            
            formData.forEach((value, key) => {
                params.append(key, value as string);
            });

            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: params.toString(),
            })
            .then(() => {
                showSuccess()
            })
            .catch((error) => {
                console.error(error)
                showServerError()
            })

            // sendData("/.netlify/functions/contact-form-handler", formData)
            // .then(() => {
            //     showSuccess()
            // })
            // .catch((error) => {
            //     console.error(error)
            //     showServerError()
            // })
        } else {
            showError()
        }
    }

    return (
        <div className="contact-section" id="contact">
            <Toast ref={toast} />
            <h2 className="contact-section__headline">
                Let's Talk
            </h2>
            <p className="contact-section__description">
                Want to change the way you work with architectural plans?
                <br />
                <br />
                Fill out the form below and we'll be in touch!
            </p>
            <div className="contact-section__form-container" >
                <form 
                    className="contact-section__form" name="contact"
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit(e)
                    }}
                >
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="contact-section__form__input-row">
                        <span className="p-float-label contact-section__form__input">
                            <InputText 
                                id="firstname"
                                name="first-name"
                                type="text"
                                value={firstName} 
                                className={firstNameError ? "p-invalid" : ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} 
                            />
                            <label htmlFor="firstname">First Name*</label>
                        </span>
                        <span className="p-float-label contact-section__form__input">
                            <InputText
                                id="lastname"
                                type="text"
                                name="last-name"
                                value={lastName}
                                className={lastNameError ? "p-invalid" : ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)}
                            />
                            <label htmlFor="lastname">Last Name*</label>
                        </span>
                    </div>
                    <div className="contact-section__form__input-row">
                        <span className="p-float-label contact-section__form__input">
                            <InputText
                                id="email"
                                type="email"
                                name="email"
                                value={email}
                                className={emailError ? "p-invalid" : ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                            />
                            <label htmlFor="email">Work Email*</label>
                        </span>
                        <span className="p-float-label contact-section__form__input">
                            <InputText
                                id="companyname"
                                type="text"
                                name="company-name"
                                value={companyName}
                                className={companyNameError ? "p-invalid" : ""}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value)}
                            />
                            <label htmlFor="companyname">Company Name*</label>
                        </span>
                    </div>
                    <div className="contact-section__form__input-row">
                        <span className="p-float-label contact-section__form__input-area">
                            <InputTextarea
                                id="message"
                                name="message"
                                value={message}
                                className={messageError ? "p-invalid" : ""}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMessage(e.target.value)}
                                rows={5}
                                autoResize
                            />
                            <label htmlFor="message">Message*</label>
                        </span>
                    </div>
                    <div className="contact-section__form__checkbox-row">
                        <input type="checkbox" name="subscribe" checked={subscribeToUpdates} hidden readOnly/>
                        <Checkbox 
                            inputId="subscribeToUpdates" 
                            className="contact-section__form__checkbox"
                            checked={subscribeToUpdates} 
                            onChange={(e) => setSubscribeToUpdates(e.checked? true : false)} 
                        />
                        <label htmlFor="subscribeToUpdates" >
                            I would like to subscribe to occasional updates about ArchiLabs
                        </label>
                    </div>
                    <button className="hero-section__cta" type="submit">
                        <span className="hero-section__cta__text">
                            &nbsp;&nbsp;&nbsp;Contact Us&nbsp;&nbsp;&nbsp;
                        </span>
                    </button>
                </form>
            </div>
        </div>
    )
}

async function sendData(url: string, data: any): Promise<any> {
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
  
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}, ${response}`);
    }
  
    return await response.json();
}