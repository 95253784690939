import "./HomePage.css"
import ContactSection from "../components/ContactSection"
import FooterSection from "../components/FooterSection"
import HeroSection from "../components/HeroSection"
import Navbar from "../components/Navbar"
import ProductDescriptionSection from "../components/ProductDescriptionSection"
import VisionSection from "../components/VisionSection"
import React from "react"
import VideoSection from "../components/VideoSection"

export default function HomePage() {
    return (
        <div className="homepage">
            <Navbar />
            <HeroSection />            
            <VideoSection />
            <ContactSection />
            <FooterSection />
        </div>
    )
}