import "./VideoSection.css"
import React from "react";

export default function VideoSection() {
    return (
        <div className="video-section__wrapper">
            <div className="video-section">
                <div className="video-section__video-container">
                    <iframe 
                        src="https://www.loom.com/embed/aaa3f2c0362d4144b15663940ab512f2?sid=cc8e9f00-418d-40ae-a5de-d4d63a388c70&hide_share=true&hide_title=true&hideEmbedTopBar=truehide_speed=true" 
                        frameBorder="0" 
                        allowFullScreen 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        className="video-section__video"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}